<template>
  <span>
    <highcharts :options="chartOptions" :deepCopyOnUpdate="false" />
  </span>
</template>

<script>
  import columnChartMixin from "./columnChartMixin";

  export default {
    name: "ChartTotalReclamacaoPorCompetencia",
    mixins: [columnChartMixin],
    props: {
      dadosPorCompetencia: {
        type: Object,
        required: true,
      },
      categories: {
        type: Array,
        required: true
      }
    },
    data: () => ({
      chartOptions: {},
    }),
    methods: {
      setChartOptions() {
        this.chartOptions = this.getColumnChartOptions(
          this.getTotalReclamacaoPorCompetenciaRemainingChartOptions()
        );
      },
      getTotalReclamacaoPorCompetenciaRemainingChartOptions() {
        return {
          xAxis: {
            type: "category",
            title: {
              text: "Competência",
            },
          },
          yAxis: {
            title: {
              text: null,
            },
            stackLabels: {
              enabled: true,
            },
          },
          legend: { enabled: true },
          plotOptions: {
            column: {
              stacking: "normal",
              dataLabels: {
                enabled: true,
              },
            },
          },
          series: [
            {
              name: "Improcedentes",
              data: this.categories
                .map((category) => {
                  return category in this.dadosPorCompetencia
                    ? {
                        name: category,
                        y: Number(
                          this.dadosPorCompetencia[category].rec_improcedentes
                        ),
                      }
                    : {
                        name: category,
                        y: 0,
                      };
                })
                .filter((val) => val !== null && val.y !== null && val.y > 0),
            },
            {
              name: "Procedentes",
              data: this.categories
                .map((category) => {
                  return category in this.dadosPorCompetencia
                    ? {
                        name: category,
                        y: Number(
                          this.dadosPorCompetencia[category].rec_procedentes
                        ),
                      }
                    : {
                        name: category,
                        y: 0,
                      };
                })
                .filter((val) => val !== null && val.y !== null && val.y > 0),
            },
          ],
        };
      },
    },
    watch: {
      dadosPorCompetencia() {
        this.setChartOptions();
      },
    },
  };
</script>

<style></style>
